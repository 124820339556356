//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Multiselect from './Multiselect.vue';

export default {
    name: 'LanguageSwitch',
    components: { Multiselect },
    mixins: [require('../mixins/product_range.js').default],
    computed: {
        ...mapGetters(['getPageSlugs']),
        productRange() {
            return this.getSelectedProductRange().toLowerCase();
        },
    },
    props: ['onFocus', 'isMobile', 'footer'],
    data() {
        return {
            selected: null,
            options: null,
            isOpen: false,
            disabled: true,
        };
    },
    created() {
        if (!this.getPageSlugs) {
            return;
        }

        try {
            // map selectable options (languages)
            const options = Object.keys(this.getPageSlugs).map((key) => {
                return {
                    label: this.$i18n.t(`languageswitch.label.${key}`),
                    code: key,
                    slug: this.getPageSlugs[key],
                    value: key,
                };
            });

            if (options) {
                this.options = options.sort((a, b) => a?.label?.localeCompare(b?.label) ?? 0);
                this.disabled = options.length <= 1;

                // set active locale
                this.selected = this.options.filter((item) => {
                    return item.value === this.$i18n.locale;
                });
            }
        } catch (e) {
            console.error(e);
        }
    },
    // mounted() {
    //     this.$nuxt.$on('sub-menu.close', () => {
    //         console.log('CLOSE LanguageSwitch');
    //         this.$nextTick(() => {
    //             this.onClose();
    //         });
    //     });
    // },
    methods: {
        // handle selection
        onChange(option) {
            if (option) {
                this.$cookies.set('base_language', option.code, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30,
                });
                const { slug } = option;
                if (slug) {
                    const urlParams = window.location.href.split('?')?.[1];
                    window.location = slug + `${urlParams ? '?' + urlParams : ''}`;
                }
            }
        },
        onOpen() {
            this.isOpen = true;
            if (this.onFocus) this.onFocus();
        },
        onClose() {
            this.isOpen = false;
        },
    },
};
